import React from 'react'
import {Autocomplete,TextField, Grid, Fade, Stack} from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import Transition from '../../components/Transition/Transition';
import PageHeader from '../../components/PageHeader/PageHeader';
import NewsItem from '../../components/NewsItem/NewsItem';
import allEvents from "../../assets/data/allEvent.json";
import { useLocalStorage } from "../../components/Localstorage/useLocalStorage";



function News() 
{

  const [eventList, setEventList] = useState(allEvents);
  const [yearOption, setYearOption] = useLocalStorage("yearOption", "2024");;
  const { t } = useTranslation();

  useEffect(() =>
  {
    filterItems();
  
  }, [yearOption]);
  const filterItems = ()=>
    {
      console.log(yearOption);
      if(yearOption.length>0)
      {
        let tempItems = allEvents.filter((event)=>event.year==yearOption);
        setEventList(tempItems);
      }

        
    }
  return (
    <Stack direction="row" sx={{justifyContent:"center", minHeight:"65.5vh", backgroundColor:"background.main"}}>
      <Fade in={true} timeout={2000}>
      <Stack direction="column" height="100%" width={{lg:"70%", md:"95%", xs:"100%"}}>
      <PageHeader weight={t("NavBar.PrevAct.length")}>{t("NavBar.PrevAct.title")}</PageHeader>
      <Stack marginBottom="20px" direction="row" justifyContent={{xs:"center",md:"flex-start"}}><Autocomplete size="small" 
  disablePortal
  onChange={(event, newValue) => {
    setYearOption(newValue);
  }}
  options={[
    '2024','2014','2013','2012']}
  sx={{ width: 300 }}
  defaultValue={yearOption}
  renderInput={(params) => <TextField variant="filled" {...params} label={t("News.function.Year")} />}
/></Stack>
        <Grid container spacing="5vh" direction="column" justifyContent="center" width="100%" marginBottom={2}>
       
          {   
          
          eventList.map((event)=>{
                return (
                <NewsItem thumbnail={event.thumbnail} title={event.title} date={event.date} id={event.id}/>
                )
            })
          }
        </Grid>
      </Stack></Fade>
    </Stack>
  )  
}export default Transition(News);